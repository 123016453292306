import React, { useContext, useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import {
  SET_ALL_PRODUCTS_DATA,
  SET_LOADING_OFF,
  SET_LOADING_ON,
} from '../../context/actions/ActionTypes';
import { ERROR_ENTITY, GQL_GET_ALL_PRODUCTS } from '../../constants';
import { gql, useLazyQuery } from '@apollo/client';
import { errorHandler, showUnexpectedError } from '../../errorHandler';
import ProductCard from './ProductCard';
import { useTranslation } from 'react-i18next';

type Props = {
  lang: string;
};

/**
 * AllProducts
 *
 * @param props
 * @constructor
 */
const AllProducts: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const allProducts = state.allProducts;

  const [
    getAllProductsData,
    {
      loading: allProductsDataLoading,
      data: allProductsData,
      error: allProductsDataError,
    },
  ] = useLazyQuery(
    gql`
      ${GQL_GET_ALL_PRODUCTS}
    `,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    dispatch({
      type: SET_LOADING_ON,
    });

    getAllProductsData();
  }, []);

  useEffect(() => {
    if (allProductsDataLoading) {
      dispatch({
        type: SET_LOADING_ON,
      });
      return;
    } else {
      dispatch({
        type: SET_LOADING_OFF,
      });
    }

    if (allProductsDataError) {
      errorHandler(
        dispatch,
        t,
        allProductsDataError,
        ERROR_ENTITY.PRODUCT,
        props.lang,
      );

      return;
    }

    if (allProductsData) {
      if (allProductsData?.dataProducts) {
        dispatch({
          type: SET_ALL_PRODUCTS_DATA,
          allProducts: allProductsData.dataProducts,
        });

        return;
      }

      // Handling unexpected error (should never happen)
      showUnexpectedError(dispatch);

      return;
    }
  }, [allProductsDataLoading, allProductsData, allProductsDataError]);

  return (
    <>
      {allProducts?.length
        ? allProducts.map((v, k) => {
            return (
              <ProductCard
                key={k}
                productId={v.productId}
                name={v.name}
                description={v.description}
                lang={props.lang}
              />
            );
          })
        : ''}
    </>
  );
};

export default AllProducts;
