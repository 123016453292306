import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/common/breadcrumbs/Breadcrumbs';
import Layout from '../components/layout/Layout';
import SideBar from '../components/layout/SideBar';
import AllProducts from '../components/product/AllProducts';
import { getPageLink } from '../constants';
import './AllProductsPage.scss';
import SearchBar from '../components/layout/SearchBar';
import { PageContentType } from '../context/PageContentType';

/**
 * All products page
 *
 * @param props
 * @constructor
 */
const AllProductsPage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.products'),
      path: getPageLink(props.pageContext.lang, 'ALL_PRODUCTS_PAGE'),
    },
  ];

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'ALL_PRODUCTS_PAGE')}
      pageTitle={t('allProductsPage.title')}
    >
      <div className="ontario-row">
        <div className="ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />

          <SearchBar lang={props.pageContext.lang} />

          <h1>{t('allProductsPage.title')}</h1>

          <AllProducts lang={props.pageContext.lang} />
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default AllProductsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
